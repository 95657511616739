<script>
export default {
    name: "LiquidWaveProgress",
    props: {
        value: {
            type: Number,
            required: true
        },
        max: {
            type: Number,
            default: 100
        },
        subText: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: '#f9f08b'
        },
        fullColor: {
            type: String,
            default: '#7bf1a8'
        },
        textColor: {
            type: String,
            default: '#4a4a4a'
        },
        size: {
            type: Number,
            default: 96
        },
        animationDuration: {
            type: Number,
            default: 3
        },
        percentageSign: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            waveWeight: 420
        }
    },
    computed: {
        waveYPosition() {
            const value = this.value > this.max ? this.max : this.value
            const percentage = (value / this.max).toFixed(2)
            return 500 - (percentage * 1000)
        },
        textToShow() {
            return this.percentageSign
                ? `${this.value.toLocaleString('es-ES', { maximumFractionDigits: 2 })}%`
                : `${this.value.toLocaleString('es-ES', { maximumFractionDigits: 2 })} / ${this.max.toLocaleString('es-ES', { maximumFractionDigits: 2 })}`
        },
        progressColor() {
            return this.value >= this.max
                ? this.fullColor
                : this.color
        }
    },
    methods: {
        animateWaveWeight() {
            let start = null
            let isReverse = false
            const duration = this.animationDuration * 1.5 * 1000
            const callback = (timestamp) => {
                if (start === null) {
                    start = timestamp
                    window.requestAnimationFrame(callback)

                    return
                }

                const totalValueToAnimate = 40
                const progress = (timestamp - start) / (duration / 2)
                const value = isReverse
                    ? 460 - (progress * totalValueToAnimate)
                    : 420 + (progress * totalValueToAnimate)

                if (value !== this.waveWeight) {
                    this.waveWeight = Math.round(value)
                    }

                if (progress >= 1) {
                    start = null
                    isReverse = !isReverse
                }

                window.requestAnimationFrame(callback)
            }

            window.requestAnimationFrame(callback)
        }
    },
    mounted() {
        this.animateWaveWeight()
    }
}
</script>

<template>
    <div
        :style="{ width: `${size}px`, height: `${size}px`, border: `4px solid ${progressColor}` }"
        class="is-relative rounded-total"
    >
        <div
            style="overflow: hidden; position: absolute; width: 90%; height: 90%; top: 5%; left: 5%;"
            class="rounded-total"
        >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 1000 1000"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                overflow="auto"
                shape-rendering="auto"
                fill="#ffffff"
            >
                <defs>
                    <path id="wavepath" :d="`M 0 2000 0 500 Q 150 ${value === max ? 500 : waveWeight} 300 500 t 300 0 300 0 300 0 300 0 300 0  v1000 z`" />
                    <path id="motionpath" d="M -600 0 0 0" />
                </defs>
                <g>
                    <use
                        xlink:href="#wavepath" :y="waveYPosition"
                        :fill="progressColor"
                    >
                        <animateMotion
                            v-if="value < max && value > 0"
                            :dur="typeof animationDuration === 'string' ? animationDuration : `${animationDuration}s`"
                            repeatCount="indefinite">
                            <mpath xlink:href="#motionpath" />
                        </animateMotion>
                    </use>
                </g>
            </svg>
        </div>

        <h3
            class="title"
            :class="{ 'is-3': percentageSign, 'is-4': !percentageSign }"
            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; line-height: .75"
            :style="{ color: textColor, maxWidth: `${this.size - 32}px`, maxHeight: `${this.size - 32}px` }"
        >
            <span>{{ textToShow }}</span>

            <br v-if="subText.length > 0" />

            <small
                v-if="subText.length > 0"
                style="font-weight: 300; font-size: .5rem; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
            >
                {{ subText }}
            </small>
        </h3>
    </div>
</template>
