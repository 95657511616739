<script>
export default {
    name: "ShareReferralCodeCard",
    props: {
        user: {
            type: Object,
            required: true
        },
        hasLink: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        referralLink() {
            const path = this.$router.resolve({
                name: 'Logup', query: { referral_code: this.user.referral_code }
            })
                .href

            return `${window.location.origin}${path}`
        }
    },
    methods: {
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$buefy.toast.open({
                        message: 'Copiado al portapapeles',
                        type: 'is-success',
                        position: 'is-top-right',
                        duration: 2000
                    })
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al copiar al portapapeles',
                        type: 'is-danger',
                        position: 'is-top-right',
                        duration: 2000
                    })
                });
        }
    }
}
</script>

<template>
    <div
        class="box p-2 bg-gradient-green-to-blue"
        :class="{ 'animate-shake-box': hasLink }"
    >
        <div class="box shadow-none bg-opacity-80 backdrop-blur">
            <div class="is-flex is-justify-content-space-between">
                <div style="max-width: 70%;">
                    <h2 class="subtitle" style="color: #016630;">
                        Código de referido
                    </h2>

                    <p class="is-size-7" style="color: #0d542b;">
                        ¡Comparte tu código de referido con tus amigos y conocidos, y gana recompensas!
                    </p>
                </div>

                <b-icon
                    icon="gifts"
                    type="is-primary"
                    size="is-large"
                />
            </div>

            <div
                class="is-flex is-justify-content-center is-align-items-center"
                :class="{ 'mb-3': hasLink }"
            >
                <p
                    class="is-size-4 has-text-weight-bold m-0 p-2"
                    style="cursor: pointer;" title="Haz clic para copiar"
                    @click="copyToClipboard(user.referral_code)"
                >
                    <span style="color: #0d542b;">
                        {{ user.referral_code.substring(0, 4).toUpperCase() }}
                    </span>
                    <span class="has-text-primary-dark">
                        {{ user.referral_code.substring(4).toUpperCase() }}
                    </span>
                </p>

                <b-tooltip
                    label="Copiar link de referido"
                    position="is-top"
                    type="is-dark"
                    style="margin-left: .25rem;"
                >
                    <span
                        style="cursor: pointer"
                        @click="copyToClipboard(referralLink)"
                    >
                        <b-icon
                            type="is-primary"
                            icon="link"
                            size="is-medium"
                        />
                    </span>
                </b-tooltip>
            </div>

            <div
                v-if="hasLink"
                class="is-flex is-justify-content-center is-align-items-center"
            >
                <router-link
                    :to="{ name: 'ReferralScore' }"
                    class="button is-rounded is-small bg-gradient-green-to-blue bg-gradient-green-to-blue-hoverable has-text-black"
                    style="border: none;"
                >
                    Ver Puntaje
                </router-link>
            </div>
        </div>
    </div>
</template>

<style>
.bg-gradient-green-to-blue {
    background: rgb(84,222,153);
    background: linear-gradient(135deg, rgba(84,222,153,1) 15%, rgba(119,226,231,1) 50%, rgba(93,141,246,1) 85%);
}

.bg-gradient-green-to-blue-hoverable {
    transition: background-color, color 300ms ease-in-out;
}

.bg-gradient-green-to-blue-hoverable:hover {
    background: rgb(69, 186, 128);
    background: linear-gradient(135deg, rgb(74, 202, 137) 15%, rgb(103, 208, 214) 50%, rgb(75, 119, 218) 85%);
}

.shadow-none {
    box-shadow: none !important;
}

.bg-opacity-80 {
    background-color: rgba(255, 255, 255, 0.80) !important;
}

.backdrop-blur {
    backdrop-filter: blur(8px);
}

@keyframes shake-box {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    2.5% { transform: translate(-1px, -2px) rotate(-1deg); }
    5% { transform: translate(-3px, 0px) rotate(1deg); }
    7.5% { transform: translate(3px, 2px) rotate(0deg); }
    10% { transform: translate(1px, -1px) rotate(1deg); }
    12.5% { transform: translate(-1px, 2px) rotate(-1deg); }
    15% { transform: translate(-3px, 1px) rotate(0deg); }
    17.5% { transform: translate(3px, 1px) rotate(-1deg); }
    20% { transform: translate(-1px, -1px) rotate(1deg); }
    22.5% { transform: translate(1px, 2px) rotate(0deg); }
    25% { transform: translate(1px, -2px) rotate(-1deg); }
    25.5% { transform: translate(0, 0) rotate(0deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
}

.animate-shake-box {
    animation-name: shake-box;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 5;
    animation-delay: 1s;
}

.animate-shake-box:hover {
    animation: none;
}
</style>
