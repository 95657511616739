<script>
import ShareReferralCodeCard from '../../views/ReferralProgram/ShareReferralCodeCard.vue'
import LiquidWaveProgress from '@/components/LiquidWaveProgress.vue'

export default {
    name: "ReferralScore",
    components: {LiquidWaveProgress, ShareReferralCodeCard},
    data() {
        return {
            referrerScore: [],
            referredScore: [],
            systemConfig: [],
            isFetched: {
                referrerScore: false,
                referredScore: false,
                systemConfig: false
            }
        }
    },
    computed: {
        isLoaded() {
            return this.$root.user.referral_code !== undefined
                && this.$root.user.referral_code !== null
                && this.isFetched.referrerScore
                && this.isFetched.referredScore
                && this.isFetched.systemConfig
        },
        isReferred() {
            return this.$root.user.referrer !== null && this.$root.user.referrer !== undefined
        },
        isReferrer() {
            return Array.isArray(this.$root.user.referrals) && this.$root.user.referrals.length > 0
        },
        hasReferralCode() {
            return this.$root.user.referral_code !== null && this.$root.user.referral_code !== undefined
        },
        minToPay() {
            const minToPay = this.systemConfig.find(config => config.key === 'referral_min_to_pay')

            return minToPay !== undefined && minToPay.value !== undefined && minToPay.value !== null
                ? minToPay.value
                : 0
        },
        referredLimit() {
            const referredLimit = this.systemConfig.find((config) => config.key === 'referral_rd_limit_score_in_program')

            return referredLimit !== undefined
                && referredLimit.value !== undefined
                && referredLimit.value !== null
                && referredLimit.value === -1
                    ? Infinity
                    : referredLimit !== undefined && referredLimit !== null
                        ? referredLimit.value
                        : 0
        },
        referrerLimit() {
            const referrerLimit = this.systemConfig.find((config) => config.key === 'referral_rr_limit_score_in_program')

            return referrerLimit !== undefined
                && referrerLimit.value !== undefined
                && referrerLimit.value !== null
                && referrerLimit.value === -1
                    ? Infinity
                    : referrerLimit !== undefined && referrerLimit !== null
                        ? referrerLimit.value
                        : 0
        },
        availableReferredScoreSum() {
            return this.referredScore.reduce(
                (acc, curr) => curr.is_claimed ? acc : acc + curr.points,
                0
            )
        },
        availableReferrerScoreSum() {
            return this.referrerScore.reduce(
                (acc, curr) => curr.is_claimed ? acc : acc + curr.points,
                0
            )
        }
    },
    methods: {
        getReferrerScore() {
            this.$http('/api/referral-program/referrer-score', {
                method: 'GET',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                }
            })
                .then(({ data }) => {
                    this.referrerScore = data
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al obtener el puntaje de referente',
                        type: 'is-danger',
                        position: 'is-top-right',
                        duration: 2000
                    })
                })
                .finally(() => {
                    this.isFetched.referrerScore = true
                })
        },
        getReferredScore() {
            this.$http('/api/referral-program/referred-score', {
                method: 'GET',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                }
            })
                .then(({ data }) => {
                    this.referredScore = data
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al obtener el puntaje de referido',
                        type: 'is-danger',
                        position: 'is-top-right',
                        duration: 2000
                    })
                })
                .finally(() => {
                    this.isFetched.referredScore = true
                })
        },
        getSystemConfig() {
            this.$http('/api/config', {
                method: 'GET'
            })
                .then(({ data }) => {
                    this.systemConfig = data
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al obtener la configuración del sistema',
                        type: 'is-danger',
                        position: 'is-top-right',
                        duration: 2000
                    })
                })
                .finally(() => {
                    this.isFetched.systemConfig = true
                })
        }
    },
    mounted() {
        this.getReferrerScore()
        this.getReferredScore()
        this.getSystemConfig()
    }
}
</script>

<template>
    <div class="container mt-5 px-4 mb-6">
        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Programa de Referidos
                </h1>
            </div>
        </div>

        <div
            v-if="isLoaded && hasReferralCode"
            class="columns is-flex-wrap-wrap pt-4"
        >
            <div class="column is-12-mobile is-4 is-order-2-tablet">
                <ShareReferralCodeCard
                    :user="this.$root.user"
                    :has-link="false"
                    class="mb-4"
                />

                <div
                    v-if="isReferred"
                    class="box mb-4"
                >
                    <h2 class="subtitle has-text-primary-dark is-size-6 mb-4">
                        Tu Referente
                    </h2>

                    <div class="is-flex is-align-items-center px-4">
                        <div style="flex-shrink: 0;">
                            <avatar
                                :username="$root.user.referrer.name"
                                :initials="`${$root.user.referrer.name.charAt(0)}${$root.user.referrer.lastname.charAt(0)}`"
                                color="#f5f5f5"
                                :size="48"
                            />
                        </div>

                        <div
                            style="flex-shrink: 1; flex-grow: 1;"
                            class="ml-4"
                        >
                            <h3 class="subtitle has-text-centered is-size-6 mb-0">
                                {{ $root.user.referrer.name }} {{ $root.user.referrer.lastname }}
                            </h3>
                        </div>
                    </div>
                </div>

                <div
                    class="box"
                >
                    <h2 class="subtitle has-text-primary-dark is-size-6 mb-4">
                        Tus Referidos
                    </h2>

                    <template v-if="isReferrer">
                        <div
                            v-for="referral in $root.user.referrals"
                            :key="`referral-${referral.id}`"
                            class="is-flex is-align-items-center p-4 border-not-last"
                        >
                            <div style="flex-shrink: 0;">
                                <avatar
                                    :username="referral.name"
                                    :initials="`${referral.name.charAt(0)}${referral.lastname.charAt(0)}`"
                                    color="#f5f5f5"
                                    :size="48"
                                />
                            </div>

                            <div
                                style="flex-shrink: 1; flex-grow: 1;"
                                class="ml-4"
                            >
                                <h3 class="subtitle has-text-centered is-size-6 mb-2">
                                    {{ referral.name }} {{ referral.lastname }}
                                </h3>

                                <p class="has-text-centered has-text-grey-light is-italic is-size-7 m-0">
                                    {{ $moment(referral.created_at).fromNow() }}
                                </p>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <p class="has-text-centered is-italic has-text-grey">
                            Aún no tienes referidos.
                        </p>
                    </template>
                </div>
            </div>

            <div class="column is-12-mobile is-8 is-order-1-tablet">
                <div
                    class="box has-background-info-dark mb-0"
                    style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
                >
                    <p
                        class="has-text-centered has-text-white mx-auto px-4 py-4"
                        style="max-width: 380px;"
                    >
                        Puedes cobrar tus puntos cuando realices tu próxima transacción, siempre y cuando hayas acumulado el mínimo permitido.
                    </p>
                </div>

                <div
                    class="box mb-6"
                    style="border-top-left-radius: 0; border-top-right-radius: 0;"
                >
                    <div
                        v-if="isReferred && referredLimit !== 0"
                        class="columns is-flex-wrap-wrap"
                    >
                        <div class="column is-mobile-12 is-12">
                            <h2 class="subtitle has-text-primary-dark is-size-6 mb-4">
                                Tu Puntaje Como Referido
                            </h2>
                        </div>

                        <div class="column is-mobile-12 is-6 is-order-2-tablet">
                            <LiquidWaveProgress
                                :value="availableReferredScoreSum"
                                :max="minToPay"
                                :percentage-sign="false"
                                :size="256"
                                sub-text="acumulado / mínimo para cobrar"
                                class="mx-auto mb-4"
                            />
                        </div>

                        <div class="column is-mobile-12 is-6 is-flex is-flex-direction-column is-justify-content-center">
                            <p class="px-4">
                                Si eres un referido, también puedes ganar puntos por cada transacción que realices.
                                <span v-if="referredLimit !== Infinity">
                                    Puedes acumular hasta un máximo de
                                    <b>
                                        {{ referredLimit.toLocaleString('es-ES', { maximumFractionDigits: 2 }) }} puntos
                                    </b>
                                    en el programa.
                                </span>
                            </p>
                        </div>
                    </div>

                    <div
                        v-if="isReferrer && isReferred"
                        class="line-divider"
                    />

                    <div
                        v-if="isReferrer && referrerLimit !== 0"
                        class="columns is-flex-wrap-wrap"
                    >
                        <div class="column is-mobile-12 is-12">
                            <h2 class="subtitle has-text-primary-dark is-size-6 mb-4">
                                Tu Puntaje Como Referente
                            </h2>
                        </div>

                        <div class="column is-mobile-12 is-6 is-order-2-tablet">
                            <LiquidWaveProgress
                                :value="availableReferrerScoreSum"
                                :max="minToPay"
                                :percentage-sign="false"
                                :size="256"
                                sub-text="acumulado / mínimo para cobrar"
                                class="mx-auto mb-4"
                            />
                        </div>

                        <div class="column is-mobile-12 is-6 is-flex is-flex-direction-column is-justify-content-center">
                            <p class="px-4">
                                Acumula puntos por cada transacción que realicen tus referidos, ¡Mientras más referidos tengas, más puntos acumularás!
                                <span v-if="referrerLimit !== Infinity">
                                    Puedes acumular hasta un máximo de
                                    <b>
                                        {{ referrerLimit.toLocaleString('es-ES', { maximumFractionDigits: 2 }) }} puntos
                                    </b>
                                    en el programa.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.border-not-last:not(:last-child) {
    border-bottom: 1px solid #dcdee3;
}
</style>
