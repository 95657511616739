import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Logup from '../views/Logup.vue'
import Identified from '../views/Identify/Identified.vue'
import Accounts from '../views/Accounts.vue'
import History from '../views/History.vue'
import Profile from '../views/Profile.vue'
import EditAvatar from '../views/EditAvatar.vue'
import Transaction from '../views/Transaction.vue'
import Report from '../views/Report.vue'
import VerificationEmail from '../views/VerificationEmail.vue'
import NewTransaction from '../views/NewTransaction.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ToCompletePre from '../views/ToCompletePre.vue';
import AccountDeletionRequestMain from "../views/AccountDeletionRequest/AccountDeletionRequestMain.vue";
import ReferralScore from '../views/ReferralProgram/ReferralScore.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/ingresar',
    name: 'Login',
    component: Login
  },
  {
    path: '/registrarse',
    name: 'Logup',
    component: Logup
  },
  {
    path: '/verificar-identidad',
    name: 'Identified',
    component: Identified
  },
  {
    path: '/cuentas-bancarias',
    name: 'Accounts',
    component: Accounts
  },
  {
    path: '/historial',
    name: 'History',
    component: History
  },
  {
    path: '/transaccion/:reference',
    name: 'Transaction',
    component: Transaction
  },
  {
    path: '/mi-perfil',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/editar-avatar',
    name: 'EditAvatar',
    component: EditAvatar
  },
  {
    path: '/reporte',
    name: 'Report',
    component: Report
  },
  {
    path: '/verificar-email',
    name: 'VerificationEmail',
    component: VerificationEmail
  },
  {
    path: '/nueva-transaccion',
    name: 'NewTransaction',
    component: NewTransaction
  },
  {
    path: '/recuperar-contrasena',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/completar-registro',
    name: 'ToCompletePre',
    component: ToCompletePre
  },
  {
    path: '/eliminar-mi-cuenta',
    name: 'AccountDeletionRequestMain',
    component: AccountDeletionRequestMain
  },
  {
    path: '/programa-de-referidos',
    name: 'ReferralScore',
    component: ReferralScore
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

var rg4js = require('raygun4js'); // Or this syntax
router.afterEach((to) => {
  rg4js('trackEvent', {
    type: 'pageView',
    path: to.path
  });
})
export default router
